import './styles.scss';

import React, {
	useRef,
	useEffect,
	useImperativeHandle,
	useContext,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSpring, animated } from 'react-spring';
import { PageContext } from '@assets/scripts/context/page-context';
import useSharedSetter from '@assets/scripts/hooks/useSharedSetter';
import useViewport from '@assets/scripts/hooks/useViewport';
import useInputDevice from '@assets/scripts/hooks/useInputDevice';
import { getElementOffsetTop } from '@assets/scripts/helpers/getElementOffsetTop';
import { getElementOffsetLeft } from '@assets/scripts/helpers/getElementOffsetLeft';
import uniqueId from '@assets/scripts/helpers/uniqueId';

const HeroTitle = React.forwardRef(
	({ className, title, subtitle = '', ...otherProps }, forwardedRef) => {
		const rootClass = cx('hero-title-wrapper', className);

		// refs
		const refEl = useRef(null);
		const refTitleOriginal = useRef(null);
		const refTitleClone = useRef(null);
		const refSubtitle = useRef(null);
		const refBorder = useRef(null);
		// forwarded ref
		useImperativeHandle(forwardedRef, () => ({
			titleWrapper: () => {
				return refEl.current;
			},
			titleOriginal: () => {
				return refTitleOriginal.current;
			},
			titleClone: () => {
				return refTitleClone.current;
			},
			subtitle: () => {
				return refSubtitle.current;
			},
		}));

		// vars
		const isMouseInside = useRef(false);
		const aboutToRelease = useRef();
		const releaseGradientPosition = useRef();
		const measures = useRef({
			cloneXY: [0, 0], // relative to document
		});
		const { isTransitioning } = useContext(PageContext);
		const { isUsingTouch } = useInputDevice();
		const id = useRef(uniqueId('heroTitle'));

		// define springs
		const [gradientPosition, setGradientPosition] = useSpring(() => ({
			xy: [-999, -999],
			config: { mass: 5, tension: 750, friction: 140 },
		}));

		const captureGradientPosition = useSharedSetter(
			id.current,
			(xy) => {
				setGradientPosition({
					xy: xy,
				});
			},
			[-999, -999]
		);

		// when viewport size changes
		useViewport(({ viewportChanged }) => {
			if (viewportChanged) {
				measures.current.cloneXY = [
					getElementOffsetLeft(refTitleClone.current),
					getElementOffsetTop(refTitleClone.current),
				];
			}
		});

		/* eslint-disable react-hooks/exhaustive-deps */
		useEffect(() => {
			refBorder.current.addEventListener('mousemove', mouseMove);
			// reveal the red gradient after a while
			// NOTE: we do it this way to ensure it doesn't reveal before the title iself on the homepage Intro
			const timeout = setTimeout(() => {
				captureGradientPosition(() => [50, 20]);
			}, 700);

			return () => {
				refBorder.current.removeEventListener('mousemove', mouseMove);
				clearTimeout(timeout);
			};
		}, []);
		/* eslint-enable react-hooks/exhaustive-deps */

		// call this when the mouse leaves the area, to leave time for the shadow to move out smoothly
		// TODO: for situations like this we could instead track the shadow position (with spring `onFrame` callback)
		//       and stop when out of bounds, but would need to check if it doesn't hurt performance
		const scheduleRelease = () => {
			// bail early if already scheduled
			if (aboutToRelease.current) return;

			aboutToRelease.current = setTimeout(() => {
				// bail early if pointer back inside, or if already released
				if (isMouseInside.current || !releaseGradientPosition.current)
					return;
				releaseGradientPosition.current();
				releaseGradientPosition.current = undefined;
			}, 500);
		};

		const isReleaseScheduled = () => !!aboutToRelease.current;

		const cancelScheduledRelease = () => {
			if (!isReleaseScheduled()) return;
			clearTimeout(aboutToRelease.current);
			aboutToRelease.current = undefined;
		};

		// ⚡️ mouse enters: gradient follows
		const mouseEnter = () => {
			if (isUsingTouch() || isTransitioning.current) return;
			// remove mousemove listener
			refBorder.current.removeEventListener('mousemove', mouseMove);

			// update mouse state
			isMouseInside.current = true;

			// cancel releasing the shadow if it was scheduled
			cancelScheduledRelease();

			// bail early if gradient position is already captured (= animation already started)
			if (releaseGradientPosition.current) return;

			// capture gradient position (make it follow the pointer)
			// debugger;
			releaseGradientPosition.current = captureGradientPosition(
				({ pointerXY, scrollXY }) => {
					if (!isMouseInside.current) scheduleRelease();
					// return default value if measures not yet measured
					if (typeof measures.current.cloneXY === 'undefined')
						return [0, 0];

					return [
						// pointer position relative to document
						pointerXY[0] +
							scrollXY[0] -
							// el position relative to document
							measures.current.cloneXY[0],
						// pointer position relative to document
						pointerXY[1] +
							scrollXY[1] -
							// el position relative to document
							measures.current.cloneXY[1],
					];
				}
			);
		};

		// ⚡️ mouse leaves: hide gradient
		const mouseLeave = (ev) => {
			if (isUsingTouch() || isTransitioning.current) return;

			// avoid running mouseLeave when cursor inside of an anchor link but also inside the border area
			const pointerXY = [ev.clientX, ev.clientY];

			const hoveredEls = document.elementsFromPoint(
				pointerXY[0],
				pointerXY[1]
			);

			if (!hoveredEls.includes(refBorder.current)) {
				// only if border area is not one of the hovered elements
				isMouseInside.current = false;
			}
		};

		const mouseMove = () => {
			if (isUsingTouch() || isTransitioning.current) return;
			refBorder.current.removeEventListener('mousemove', mouseMove);
			mouseEnter();
		};

		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div ref={refEl} className={rootClass} data-target {...otherProps}>
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<div
					ref={refBorder}
					data-target-border
					onMouseEnter={mouseEnter}
					onMouseLeave={mouseLeave}
					aria-hidden="true"
					style={{ '--x-border': '200px', '--y-border': '150px' }}
				></div>
				{subtitle ? (
					<>
						<p
							ref={refTitleOriginal}
							className="hero-title hero-title--original"
						>
							{title}
						</p>
						<div className="hero-subtitle-wrapper">
							<h1 ref={refSubtitle} className="hero-subtitle">
								{subtitle}
							</h1>
						</div>
					</>
				) : (
					<h1
						ref={refTitleOriginal}
						className="hero-title hero-title--original"
					>
						{title}
					</h1>
				)}
				<animated.span
					ref={refTitleClone}
					className="hero-title hero-title--clone"
					aria-hidden="true"
					style={{
						background: gradientPosition.xy.interpolate(
							(x, y) =>
								`radial-gradient(33% 86% at ${x}px ${y}px, #FF0000 20%, transparent 86%)`
						),
					}}
				>
					{title}
				</animated.span>
			</div>
		);
	}
);

HeroTitle.defaultProps = {
	title: 'We amplify',
};

HeroTitle.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
};

HeroTitle.displayName = 'HeroTitle';

export default HeroTitle;
