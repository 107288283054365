import React from 'react';
import useHover from '@assets/scripts/hooks/useHover';

const Link = ({ href, children, onMouseEnter, onMouseLeave, ...props }) => {
	// interaction
	const [defaultHoverOn, defaultHoverOff] = useHover();

	return (
		<a
			href={href}
			{...props}
			onMouseEnter={() =>
				onMouseEnter ? onMouseEnter() : defaultHoverOn()
			}
			onMouseLeave={() =>
				onMouseLeave ? onMouseLeave() : defaultHoverOff()
			}
			data-target
		>
			{children}
		</a>
	);
};

Link.displayName = 'Link';

export default Link;
