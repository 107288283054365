import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';

const Detail = ({ modifiers, className, title, text, link, ...otherProps }) => {
	const baseClass = 'detail';

	const rootClass = cx(baseClass, className, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	return (
		<div
			className={rootClass}
			{...otherProps}
			data-target={link ? true : false}
		>
			<h3>{title}</h3>
			<ul>
				{text.map((line, index) => (
					<li key={`detail-${index}`}>{line}</li>
				))}
			</ul>
			{link && (
				<ButtonSecondary
					href={link.url}
					text={link.title}
					data-target
				/>
			)}
		</div>
	);
};

Detail.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	text: PropTypes.array.isRequired,
	link: PropTypes.object,
};

export default Detail;
