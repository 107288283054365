import React from 'react';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
import Detail from '@components/molecules/Detail';
import HeroPage from '@components/molecules/heros/HeroPage';

// assets
import imgLetter from '@assets/images/opened_letter.svg';

// --------------------------------
// #region data
// --------------------------------

const pageClass = 'page-contact';

const language = 'en';

const footerRelatedLinks = [
	{
		title: 'Agency',
		url: '/en/agency/',
	},
	{
		title: 'Jobs',
		url: '/en/jobs/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ContactPage = (props) => {
	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/fr/contact/', lang: 'fr' }]}
			title="Contact - Superhuit"
		>
			<HeroPage image={imgLetter} imageY={44} title="Hello!" />

			<section
				className="grid text-content text-center"
				aria-label="Introduction"
			>
				<h2 data-animation-page className="col-big">
					How may we help you?
				</h2>
			</section>

			<section className="grid" aria-label="Contact">
				<div className="box text-content" data-animation-page>
					<h3>Contact us</h3>

					<ul className="list-two-columns row-gap-1">
						<li>
							<Link href="tel:+41216521818">
								+41 (0) 21 652 18 18
							</Link>
						</li>
						<li>
							<Link href="mailto:hello@superhuit.ch">
								hello@superhuit.ch
							</Link>
						</li>
					</ul>

					<div className="list-two-columns">
						<Detail
							title="Lausanne"
							text={[
								'Rue des Terreaux 7',
								'1003 Lausanne',
								'Switzerland',
							]}
							link={{
								title: 'Locate',
								url: 'https://goo.gl/maps/xiugkuurHmAUTpTu8',
							}}
						/>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default ContactPage;
