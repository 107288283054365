import '../hero.scss';
import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import HeroTitle from '@components/atoms/HeroTitle';

const HeroPage = ({
	modifiers,
	className,
	image,
	imageRatio,
	imageX,
	imageY,
	title,
	subtitle = '',
	alt = '',
	...otherProps
}) => {
	const baseClass = 'hero--page';
	const defaultClass = 'hero';

	const rootClass = cx(baseClass, className, defaultClass, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	return (
		<div
			className={rootClass}
			style={{
				'--img-ratio': `${imageRatio}em`,
				'--img-x': `${imageX}%`,
				'--img-y': `${imageY}%`,
			}}
			data-hero
			{...otherProps}
		>
			<div className="hero__inner">
				<div data-animation-page className="hero__img">
					<img src={image} alt={alt} loading="lazy" />
				</div>
				<HeroTitle
					data-animation-page="200"
					title={title}
					subtitle={subtitle}
				/>
				<span className="hero__background" data-animation-page></span>
			</div>
		</div>
	);
};

HeroPage.defaultProps = {
	imageRatio: 2.3,
	imageX: 50,
	imageY: 50,
};

HeroPage.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default HeroPage;
